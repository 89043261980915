import { styled } from '@linaria/react';

import { RMImage } from '@/components/RMImage/RMImage';

export const DesktopDragIconContainer = styled.div`
  position: absolute;
  left: 0;
  transform: translateX(-100%);
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: var(--spacing-md);

  transition: opacity 200ms ease-in-out;
  opacity: 0;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  z-index: 1;

  &[data-show-only-on-hover='true'] {
    transition: opacity 200ms ease-in-out;
    opacity: 0;
  }

  body[data-mobile='true'] & {
    align-items: start;
    padding-left: var(--spacing-xs);
    padding-right: var(--spacing-xl);
    /* Fix scrolling issues in mobile */
    /* https://docs.dndkit.com/api-documentation/sensors/pointer#touch-action */
    touch-action: none;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  padding: var(--spacing-sm);
  padding-right: var(--spacing-xl);
  position: relative;
  background-color: var(--surface-bright);
  transition: background-color 0.1s ease-out;
  z-index: 1;
  cursor: pointer;

  &[data-dragging='true'] {
    z-index: 2;
    cursor: grabbing;
  }

  body[data-mobile='true'] & {
    padding-right: 0;
  }

  body[data-mobile='false'] & {
    &:first-child {
      border-top-left-radius: var(--radius-smooth);
      border-top-right-radius: var(--radius-smooth);
    }

    &:last-child {
      border-bottom-left-radius: var(--radius-smooth);
      border-bottom-right-radius: var(--radius-smooth);
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--border-hairline);
  }

  .drag-icon {
    transition: opacity 100ms ease-in;
    opacity: 0;

    body[data-mobile='true'] & {
      opacity: 1;
      margin-top: 6px;
    }
  }

  /* Hover state */
  &:hover {
    z-index: 10;

    &[data-dragging='false']::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      box-shadow: var(--elevation-bottom-2);
    }

    body[data-mobile='false'] {
      &:first-child::after {
        border-top-left-radius: var(--radius-smooth);
        border-top-right-radius: var(--radius-smooth);
      }

      &:last-child::after {
        border-bottom-left-radius: var(--radius-smooth);
        border-bottom-right-radius: var(--radius-smooth);
      }
    }

    .drag-icon {
      opacity: 1;
    }

    ${Right}, &[data-dragging='false'] ${DesktopDragIconContainer} {
      opacity: 1;
    }
  }

  /* Dragging state */
  &[data-dragging='true']::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--radius-round);
    border: 4px solid var(--border-primary);
    background: var(--surface-bright);
    box-shadow: var(--elevation-bottom-4);
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: var(--spacing-md);
  z-index: 1;

  body[data-mobile='true'] & {
    align-items: flex-start;
  }
`;

export const Image = styled(RMImage)`
  flex: 0 0 88px;
  height: 88px;
  width: 88px;
  object-fit: cover;
  border-radius: var(--radius-regular);

  body[data-mobile='true'] & {
    flex: 0 0 72px;
    height: 72px;
    width: 72px;
    border-radius: var(--radius-minimal);
  }
`;

export const LeftTextContent = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: var(--spacing-sm);

  body[data-mobile='true'] & {
    margin-bottom: auto;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  color: var(--on-surface-tertiary);
`;
